<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div class="min-h-screen w-full bg-teal-900 px-6">
    <!-- Header -->
    <AdminHeader />
    <!-- Search Section -->
    <div class="max-w-4xl mx-auto pb-8">
      <h1 class="text-white text-left text-2xl mb-4">
        Search Customers
        <span
          class="-ml-1 text-4xl leading-none"
          style="color: rgb(0, 163, 136);"
          >.</span
        >
      </h1>

      <!-- Search Filters -->
      <div class="flex gap-4 mb-8">
        <input
          type="text"
          placeholder="Name"
          class="flex-1 p-3 rounded-md bg-white focus:outline-none"
          v-model="searchName"
          @input="debouncedSearch"
        />
        <input
          type="tel"
          placeholder="Phone"
          class="flex-1 p-3 rounded-md bg-white focus:outline-none"
          v-model="searchPhone"
          @input="debouncedSearch"
          pattern="[0-9]*"
          maxlength="10"
        />
      </div>

      <!-- Customers Grid -->
      <div class="grid grid-cols-2 gap-x-8 gap-y-6">
        <div
          v-if="customers.length > 0"
          v-for="customer in customers"
          :key="customer.id"
          class="flex items-center space-x-4 cursor-pointer hover:opacity-80 transition-opacity md:gap-5"
          @click="viewCustomerDetails(customer)"
        >
          <!-- Customer Avatar -->
          <div class="w-16 h-16 bg-white rounded-full flex-shrink-0">
            <img
              v-if="customer.profile_image"
              :src="customer.profile_image"
              class="w-full h-full rounded-full object-cover"
              alt="customer profile"
            />
          </div>

          <!-- Customer Info -->
          <div>
            <h3 class="text-white text-lg font-medium text-left">
              {{ customer.name }}
            </h3>
            <div class="flex items-center md:space-x-2">
              <span class="text-gray-300 text-sm hidden md:block">Phone:</span>
              <span class="text-gray-300 text-sm"
                >{{ customer.country_code }} {{ customer.phone_number }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Back Button -->
      <button
        @click="$router.back()"
        class="mt-14 bg-teal-800 gap-5 text-white px-6 py-4 rounded-md flex items-center space-x-2"
        style="background-color: #204952"
      >
        <span class="material-icons text-xl arrow-left-icon">arrow_left</span>
        <span>Back</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdminHeader from "@/components/AdminHeader.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "Customers",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      logo_transition: false,
      searchName: "",
      searchPhone: "",
      customers: [],
      isLoading: false,
      fullPage: true,
      loader: "bars",
      searchTimeout: null
    };
  },
  mounted() {
    this.logo_transition = true;
    this.fetchCustomers();
  },
  methods: {
    async fetchCustomers() {
      this.isLoading = true;
      try {
        const response = await axios.get("/all/customers", {
          params: {
            name: this.searchName,
            phone: this.searchPhone
          },
          headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }



        });

        if (response.data.status) {
          this.customers = response.data.customers;
        } else {
          // Clear customers array when no customers are found
          this.customers = [];
          // Show alert for no customers
          this.$swal.fire({
            icon: "info",
            title: response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false
          });
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
        this.customers = []; // Clear customers on error
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Error fetching customers",
          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false
        });
      } finally {
        this.isLoading = false;
      }
    },
    debouncedSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.fetchCustomers();
      }, 2000); // 2 seconds delay
    },
    viewCustomerDetails(customer) {
      // Store customer data in localStorage
      localStorage.setItem(
        "selectedCustomer",
        JSON.stringify({
          id: customer.id,
          name: customer.name,
          email: customer.email,
          phone: customer.phone_number,
          country_code: customer.country_code,
          profile_image: customer.profile_image
        })
      );

      // Navigate to customer details page
      this.$router.push({
        name: "AdminCustomer",
        query: { id: customer.id }
      });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.bg-teal-900 {
  background-color: #06262d;
}
.arrow-left-icon {
  color: #00a388; /* Custom text color */
  background-color: #002f28; /* Custom background color */
  display: inline-flex; /* Ensures background color wraps the icon */
  border-radius: 4px; /* Optional rounded corners */
}

.header-padding {
  padding-left: 15%;
  padding-right: 15%;
}

@media (min-width: 768px) {
  .header-padding {
    padding-left: 15%;
    padding-right: 15%;
  }
}
@media (max-width: 767px) {
  .header-padding {
    padding-left: 0%;
    padding-right: 0%;
  }
}

/* Hide number input spinners */
input[type="tel"]::-webkit-outer-spin-button,
input[type="tel"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="tel"] {
  -moz-appearance: textfield;
}
</style>
